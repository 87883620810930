import React from "react"
import useTranslations from "../../../utils/useTranslations"
import ComparisonPanels from "../../../components/comparisonPanels"

import heroImgWebp from "../../../images/products/heroes/premium-classic.webp"
import heroImg from "../../../images/products/heroes/premium-classic.png"
import heroImgWebp2x from "../../../images/products/heroes/premium-classic@2x.webp"
import heroImg2x from "../../../images/products/heroes/premium-classic@2x.png"

import Button from "../../../components/button"
import Logo from "../../../components/logo"
import backgrounds from "../../../components/backgrounds"

const heroImgAlt = "Buy BitTorrent Classic Pro"

class BittorrentClassicPremium extends React.Component {

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { unlocalizedPath }  = this.props.pageContext

    return (
      <>
        <div className="top-section-container p-0 pb-md-5 pt-md-3 background-noise-dark" style={backgrounds.darkCorners}>

          <div className="container text-dark py-2">

            <div className="row text-center mt-3 mt-sm-5">
              <div className="col-md-6 mt-md-4 order-md-1 px-md-0 pt-lg-5">
                <Logo color="black" tag="p" productName="Classic Premium" className="mb-4"/>
                <img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4 ml-auto"></img>
                <div className="text-size-24 text-spacing-1 font-weight-normal">{t(`Malware protection and no ads.`)}</div>
                <Button  href={`${unlocalizedPath}#comparison-panels`} id="products-win-classic-premium-hero-cta" className=" btn-primary mt-3 mt-md-4 mb-4 button-filled text-spacing-1 px-5">{t(`BUY NOW`)}</Button>
              </div>
              <div className="col-md-6 mb-5 order-md-2">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>
                </picture>

              </div>
            </div>
          </div>
        </div>

        <div className="container my-5 py-md-5">
        	<p className="premium-title">{t(`<b>BitTorrent</b> Classic Pro`)}</p>
        	<p className="premium-description">
        		{t(`Try our most popular premium BitTorrent client. Keep your PC safe by automatically scanning torrent downloads for threats. Watch or preview torrents while your files download with a built-in HD media player. Convert files to play on iPhone, iPad, iPod, XBox, Playstation, Apple TV, or Android device. Enjoy an ad-free experience and get access to premium customer support!`)}
        	</p>

        	<p className="premium-title mt-5 ">{t(`<b>BitTorrent</b> Classic Ad-Free`)}</p>
        	<p className="premium-description">
        		{t(`Upgrade your favorite torrenting desktop app and go ad-free. The upgrade converts your free torrent software into a more streamlined and productive BitTorrent client experience. Also includes access to premium customer support.`)}
        	</p>

        	<p className="premium-title mt-5 ">{t(`<b>BitTorrent</b> Classic Pro+VPN`)}</p>
        	<p className="premium-description">
        		{t(`Get all Pro and Ad-Free features, including malware and virus protection, no ads, HD media player, file converter, and premium customer support. Also includes 1-year of CyberGhost VPN on up to 5 devices. Protect your online identity, safeguard your personal data from hackers, and protect your browsing activity on public networks.`)}
        	</p>
        </div>

        <div className="container-fluid position-relative overflow-hidden">
          <div className="bg-text-container bg-text-gray">
            {t(`Compare`)}
          </div>
          <div className="container my-5 text-center z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal">
                {t(`Compare BitTorrent Classic Premium Versions`)}
            </h2>
          </div>
          <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":true}} />
        </div>

        <div className="container mt-5 pt-5 mb-md-5 pt-md-0">
          <h2 className="feature-list-title-1 font-weight-normal">
              {t(`Tips on How to Speed Up BitTorrent Classic Premium Apps`)}
          </h2>
        </div>

        <div className="container my-md-5 py-md-5">
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="ml-md-5 col-md-3 text-md-right premium-tip-title mb-3 mb-md-0">
        		</div>
        		<div className="ml-md-3 col-md-7 product-faq-answer ">
        			{t(`<b>Going Pro or ad-free</b> helps streamline your BitTorrent Windows client experience, which can speed up your efficiency. However, it isn’t the only way to turn your client into fast torrenting software. Below are a few tips to help any user speed up BitTorrent downloads:`)}

        		</div>
        	</div>
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="ml-md-5 col-md-3 text-md-right premium-tip-title mb-3 mb-md-0 text-size-24 ">
        			{t(`Limit use of other bandwidth consuming <b>applications</b>`)}

        		</div>
        		<div className="ml-md-3 col-md-7 product-faq-answer">
        			{t(`<b>Look out for other apps</b>, both online and on the desktop, that consume your network resources. Web applications like Facebook, YouTube and Gmail, as well as desktop apps such as Skype, Dropbox, and Pandora can slow your downloads. Closing or limiting the use of these apps before you open your torrent client can increase torrent speed.`)}

        		</div>
        	</div>
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="ml-md-5 col-md-3 text-md-right premium-tip-title mb-3 mb-md-0 text-size-24">
        			{t(`<b>Schedule downloads</b> during off peak hours`)}

        		</div>
        		<div className="ml-md-3 col-md-7 product-faq-answer">
        			{t(`<b>BitTorrent Classic includes a scheduler</b> that limits your downloads to whatever time of day you specify. If you live in a household where web activity and bandwidth consumption is heavy during the day and in the evenings, you can schedule your downloads to run late at night or early in the morning. This can speed up BitTorrent during off peak hours and keep your normal internet tasks more productive during the day.`)}
        		</div>
        	</div>
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="ml-md-5 col-md-3 text-md-right premium-tip-title mb-3 mb-md-0 text-size-24">
        			{t(`Select only the necessary files to <b>download</b>`)}

        		</div>
        		<div className="ml-md-3 col-md-7 product-faq-answer">
        			{t(`<b>A single torrent file can be bundled</b> with several individual files (e.g. a Moby music album available on BitTorrent Now). If you don’t want to download all of the files in a torrent, you can easily stop them to speed up other prioritized files. To do this in BitTorrent Classic Pro, click into the Files tab, then right click on the file(s) you want to stop and click ‘Don’t Download’. This will optimize your torrent download speed for the files important to you.`)}
        		</div>
        	</div>
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="ml-md-5 col-md-3 text-md-right premium-tip-title mb-3 mb-md-0 text-size-24">
        			{t(`Limit online <b className='d-md-block'>streaming</b>`)}

        		</div>
        		<div className="ml-md-3 col-md-7 product-faq-answer">
        			{t(`<b>To optimize your BitTorrent download speed,</b> limit your use of video streaming sites like YouTube and Facebook, which can make your connection slower and your torrent downloads take longer.`)}
        		</div>
        	</div>


        </div>

      </>
    )

  }
}

export default BittorrentClassicPremium
