import React from "react";
import tokenBg from "../images/token/btt/group-7.png"
import darkBg4 from "../images/bg-dark4.jpg"
import darkCorners from "../images/homepage/dark-corners.jpg"


const backgrounds = {
  lightBg:{
    backgroundImage:`linear-gradient(52deg, rgba(196,196,201,0.25) 1%, rgba(247,247,247,1) 53%, rgba(196,196,201,0.25) 99%)`,
    backgroundSize:`cover`
  },
  darkBg:{
    backgroundImage:`radial-gradient(circle at 50% -30%, #545764, #2d2e33 60%)`,
    backgroundSize:`cover`
  },
  tokenBg:{
    backgroundImage:`url(${tokenBg})`,
    backgroundSize:`contain`,
    backgroundRepeat:`no-repeat`,
    backgroundPosition:`center`
  },
  darkBg4:{
    backgroundImage:`url(${darkBg4})`,
    backgroundSize:`cover`
  },
  darkCorners:{
    backgroundImage:`url(${darkCorners})`,
    backgroundSize:`cover`
  },
  greyGradientBg: {
    backgroundImage:'linear-gradient(to bottom, #ffffff, #f0f0f0)',
    zIndex: -1
  }
}

export default backgrounds;